<template>
  <span :class="className2">
    <a v-if="explorerUrl" class="btn-link" :href="explorerUrl" target="_blank" rel="noopener noreferrer">{{ text }}</a>
    <span v-else>{{ text }}</span>
    <span
      v-if="allowCopy && text"
      class="ml-2 copy-to-clipboard-info"
      aria-hidden="true"
      :title="$t('STAKING_EXPLORER_LINK_COPY_TEXT')"
      @click="copyToClipboard"
    >
      <span class="fa fa-clone" aria-hidden="true"> </span>
    </span>
  </span>
</template>

<script>
import i18n from '@/i18n';
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import stringFormat from 'string-format';
import currencyHelper from '@/utils/currency-helper';
import stringHelper from '@/utils/string-helper';
import { MD5 } from 'crypto-es/lib/md5.js';
import { env } from '@/constants';
import * as copy from 'copy-to-clipboard';
import responseHelper from '@/utils/response-helper';

const MAX_LENGTH = 'cosmos16nuyu2e2kq72pxt209h5lysw5mflz2ajjvygad'.length;

const keyResolver = (currencySymbol, type, currencies) => {
  const obj = { currencySymbol, type, currencies };
  const key = MD5(JSON.stringify(obj)).toString();

  return key;
};

const getExplorerFormatUrl = _.memoize((currencySymbol, type, currencies) => {
  if (!currencySymbol) {
    return '';
  }

  const coinInfo = _.find(currencies, {
    symbol: currencySymbol,
  });
  if (!coinInfo) {
    return '';
  }

  let formatUrl = `${_.trimEnd(coinInfo.explore_url, '/')}/${type}/{0}`;
  if (type === 'tx') {
    formatUrl = coinInfo.transaction_format_link || formatUrl;
  } else if (type === 'address') {
    formatUrl = coinInfo.address_format_link || formatUrl;
  }

  return formatUrl;
}, keyResolver);

const getExplorerUrl = (currencySymbol, value, type, currencies) => {
  if (!currencySymbol || !value) {
    return '';
  }

  const formatUrl = getExplorerFormatUrl(currencySymbol, type, currencies);
  if (!formatUrl) {
    return null;
  }

  const url = stringFormat(formatUrl, value);

  return url;
};

export default {
  name: 'StakingExplorerLink',
  data() {
    return {};
  },
  props: {
    currencySymbol: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    displayText: {
      type: String,
      default: null,
    },
    allowCopy: {
      type: Boolean,
      default: true,
    },
    shorten: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['currencies']),
    className2() {
      return `staking-explorer-link-container ${this.className} staking-explorer-link-container-${_.kebabCase(
        currencyHelper.getMappingCurrencySymbol(this.currencySymbol),
      )}`;
    },
    explorerUrl() {
      const { value, currencySymbol, type, allowCopy, shorten, currencies } = this;
      const explorerUrl = getExplorerUrl(currencyHelper.getMappingCurrencySymbol(currencySymbol), value, type, currencies);

      return explorerUrl;
    },
    text() {
      const { value, allowCopy, shorten, displayText } = this;
      if (displayText) {
        return displayText;
      }

      let text = value;
      if (!text) {
        return null;
      }

      if (shorten && text.length > MAX_LENGTH) {
        text = `${text.substr(0, MAX_LENGTH / 2)}...${text.substr(text.length - MAX_LENGTH / 2 - 10)}`;
      }

      return text;
    },
  },
  async mounted() {},
  methods: {
    copyToClipboard() {
      const { value } = this;
      const isSuccess = copy(value);
      if (isSuccess) {
        this.$notify({
          group: 'notifications',
          // type: 'success',
          // title: this.$t('STAKING_EXPLORER_LINK_SUCCESS_TITLE'),
          text: this.$t('STAKING_EXPLORER_LINK_SUCCESS_TEXT'),
        });
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_EXPLORER_LINK_ERROR_TITLE'),
          text: this.$t('STAKING_EXPLORER_LINK_ERROR_TEXT'),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.staking-explorer-link-container {
  .btn-link {
    color: #6c65e4;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .copy-to-clipboard-info {
    cursor: pointer;

    .copy-to-clipboard-text {
      font-size: 1em;
    }
  }
}
</style>
