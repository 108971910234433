<template>
  <div class="fiat-transaction-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="fiatTransactionListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{$t("FIAT_TRANSACTION_DETAILS_PAGE_BACK_LINK_BUTTON")}}
            </router-link>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>
          <CCardBody v-if="!isLoading">
            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_EMAIL_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.Member ? fiatTransactionDetails.Member.email : null}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_DATE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{fiatTransactionDetails.createdAt | moment(dateFormat)}}</span>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TRANSACTION_ID_LABEL")}}</label>              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext"><a :href="getTxLink(fiatTransactionDetails.transaction_id,fiatTransactionDetails.provider,fiatTransactionDetails.transaction_type)" target="_blank" rel="noopener noreferrer">{{ fiatTransactionDetails.transaction_id }}</a></span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_STATUS_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingBadge
                    type="fiat-transaction-status"
                    :text="fiatTransactionDetails.status"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_FROM_CURRENCY_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.from_currency}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TO_CURRENCY_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.to_cryptocurrency}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_PAYMENT_METHOD_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingBadge
                      type="fiat-transaction-payment-method"
                      :text="fiatTransactionDetails.payment_method"
                    />
                </div>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_PAYMENT_METHOD_NAME_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.payment_method_name}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_FROM_AMOUNT_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.from_amount | formatNumber(8)}} {{fiatTransactionDetails.from_currency}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TO_AMOUNT_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.to_amount}} {{fiatTransactionDetails.to_cryptocurrency}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2" v-if="fiatTransactionDetails.provider =='SENDWYRE'">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TO_ADDRESS_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4" v-if="fiatTransactionDetails.provider =='SENDWYRE'">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="fiatTransactionDetails.to_cryptocurrency"
                    :value="fiatTransactionDetails.to_address"
                  />
                </div>
              </CCol>

              <CCol lg="2" md="2" v-if="fiatTransactionDetails.provider =='MOONPAY'">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_DEPOSIT_ADDRESS_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4" v-if="fiatTransactionDetails.provider =='MOONPAY'">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="fiatTransactionDetails.from_currency"
                    :value="fiatTransactionDetails.deposit_address"
                  />
                </div>
              </CCol>

              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_RATE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.rate | formatNumber(8)}}</span>
              </CCol>
            </CRow>

            <CRow v-if="fiatTransactionDetails.provider == 'SENDWYRE'">
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TRANSACTION_FEE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.fees[fiatTransactionDetails.from_currency] | formatNumber(8)}} {{fiatTransactionDetails.from_currency}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_NETWORK_FEE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.fees[fiatTransactionDetails.to_cryptocurrency]}} {{fiatTransactionDetails.to_cryptocurrency}}</span>
              </CCol>
            </CRow>

            <CRow v-if="fiatTransactionDetails.provider == 'MOONPAY'">
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_TRANSACTION_FEE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext" v-if="fiatTransactionDetails.transaction_type == 'buy'">
                  {{fiatTransactionDetails.fees.fee | formatNumber(8)}} {{fiatTransactionDetails.from_currency}}
                </span>
                <span class="form-control-plaintext" v-else>
                  {{fiatTransactionDetails.fees.fee | formatNumber(8)}} {{fiatTransactionDetails.fee_currency}}
                </span>
              </CCol>

              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_NETWORK_FEE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext" v-if="fiatTransactionDetails.transaction_type == 'buy'">
                  {{fiatTransactionDetails.fees.network_fee || 0 }} {{fiatTransactionDetails.from_currency}}
                </span>
                <span class="form-control-plaintext" v-else>
                  {{fiatTransactionDetails.fees.network_fee || 0}} {{fiatTransactionDetails.fee_currency}}
                </span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_TOTAL_FEE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.total_fee | formatNumber(8)}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_FEE_CURRENCY_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.fee_currency}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_ORDER_ID_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.order_id}}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_ORDER_TYPE_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.order_type}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_TX_ID_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="tx"
                    :currencySymbol="fiatTransactionDetails.from_currency"
                    :value="fiatTransactionDetails.tx_id"
                    v-if="fiatTransactionDetails.transaction_type === 'sell'"
                  />

                  <StakingExplorerLink
                    v-else
                    type="tx"
                    :currencySymbol="fiatTransactionDetails.to_cryptocurrency"
                    :value="fiatTransactionDetails.tx_id"
                  />
                </div>
              </CCol>

              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_PROVIDER_LABEL")}}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{fiatTransactionDetails.provider}}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label
                  class="col-form-label"
                >{{$t("FIAT_TRANSACTION_DETAILS_PAGE_FAILED_REASON_LABEL")}}</label>
              </CCol>
              <CCol lg="10" md="10">
                <span class="form-control-plaintext">{{fiatTransactionDetails.failed_reason}}</span>
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>

import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { Constants, env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';
import StakingExplorerLink from '@/components/StakingExplorerLink';
export default {
  name: 'FiatTransactionDetail',
  components: {
    VclList,
    StakingBadge,
    StakingExplorerLink,
  },
  data(){
    return {
      fiatTrackUrl: env.wyreTrackUrl,
      dateFormat: Constants.dateFormat,
      fiatTransactionId: null,
      fiatTransactionDetails: null,
      fiatTransactionListUrl: '/admin/exchange/fiat-transactions',
      isLoading: true,
    };
  },
  async mounted() {
    this.fiatTransactionId = this.$route.params.id;
    await Promise.all([this.getFiatTransactionsDetails(this.fiatTransactionId)]);
    this.isLoading = false;
  },
  methods: {
    backToExchangeTransactionList() {
      this.$router.push(this.fiatTransactionListUrl);
    },
    async getFiatTransactionsDetails(fiatTransactionId) {
      try {
        const res = await this.$http.get(endpoints.getFiatTransactionsDetails(this.fiatTransactionId));
        this.fiatTransactionDetails = res.data;
        this.fiatTransactionDetails.fees = this.fiatTransactionDetails.fees || {};
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('FIAT_TRANSACTION_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    getTxLink(tx_id, provider, tx_type){
      let url = '';
      switch (provider.toUpperCase()){
        case 'SENDWYRE':
          url = this.fiatTrackUrl + tx_id;
          break;
        case 'MOONPAY':
          url = env.moonpaySellUrl + `?transactionId=${tx_id}`;
          if (tx_type != 'sell') {
            url = env.moonpayBuyUrl  + `?transactionId=${tx_id}`;
          }
          break;
      };
      return url;
    }
  }
};
</script>
<style lang="scss">
.fiat-transaction-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
